/**
 * @generated
 **/
import {defineStore} from 'pinia';
import {shallowRef} from 'vue';
import {command, fetchAndMergeResult, generateAggregateId, generateGetterById, query} from '@/stores/api';

export const useLocationStore = defineStore('location', () => {
  const locations = shallowRef<Locations>({});
  const myLocations = shallowRef<MyLocations>({});

  const getLocationById = generateGetterById<Location>(locations);
  const getMyLocationById = generateGetterById<MyLocation>(myLocations);

  const fetchLocations = async (filter: {[key: string]: any} = {}): Promise<void> => {
    return fetchAndMergeResult('location.find-locations', locations, filter);
  };

  const fetchMyLocations = async (filter: {[key: string]: any} = {}): Promise<void> => {
    return fetchAndMergeResult('location.find-my-locations', myLocations, filter);
  };

  const createLocation = async (data: {
    location_id?: string;
    location_label: string;
    location_address: object;
  }): Promise<void> => {
    data['location_id'] ??= generateAggregateId();
    await command('location.create-location', data);
  };

  const updateLocation = async (data: {location_id?: string; location_label: string}): Promise<void> => {
    data['location_id'] ??= generateAggregateId();
    await command('location.update-location', data);
  };

  const setLocationQualifications = async (data: {location_id?: string; qualification_ids: object}): Promise<void> => {
    data['location_id'] ??= generateAggregateId();
    await command('location.set-location-qualifications', data);
  };

  const archiveLocation = async (data: {location_id?: string}): Promise<void> => {
    data['location_id'] ??= generateAggregateId();
    await command('location.archive-location', data);
  };

  return {
    locations,
    myLocations,
    getLocationById,
    getMyLocationById,
    fetchLocations,
    fetchMyLocations,
    createLocation,
    updateLocation,
    setLocationQualifications,
    archiveLocation,
  };
});

export interface Location {
  location_id: string;
  location_label: string;
  location_address: {
    town: string;
    address_addition: string;
    postal_routing_number: string;
    street_and_house_number: string;
    country: string;
  };
  qualification_ids: string[];
  archived: boolean;
}

export interface MyLocation {
  location_id: string;
  location_label: string;
  location_address: {
    town: string;
    address_addition: string;
    postal_routing_number: string;
    street_and_house_number: string;
    country: string;
  };
}

export interface Locations {
  [key: string]: Location;
}

export interface MyLocations {
  [key: string]: MyLocation;
}
