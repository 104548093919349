<template>
  <div class="ui container" v-if="hasWarnings">
    <div class="ui small yellow icon message" v-if="!hasRegistrationAddress">
      <i class="exclamation circle icon"></i>
      <div class="content">
        <div class="header">Meldeadresse nicht hinterlegt</div>
        <p>
          Bitte vervollständige deine Angaben <i class="right angle icon divider" />
          <RouterLink :to="{name: 'user_profile', params: {tab: 'PersonalData'}}">Meldeadresse</RouterLink>
        </p>
      </div>
    </div>

    <div class="ui small yellow icon message" v-else-if="!hasPayrollData">
      <i class="exclamation circle icon"></i>
      <div class="content">
        <div class="header">Lohnangaben nicht hinterlegt</div>
        <p>
          Bitte vervollständige deine Angaben <i class="right angle icon divider" />
          <RouterLink :to="{name: 'user_profile', params: {tab: 'PayrollData'}}">Lohnangaben</RouterLink>
        </p>
      </div>
    </div>

    <div class="ui small yellow icon message" v-else-if="!hasEmergencyContact">
      <i class="exclamation circle icon"></i>
      <div class="content">
        <div class="header">Notfallkontakt nicht hinterlegt</div>
        <p>
          Bitte vervollständige deine Angaben <i class="right angle icon divider" />
          <RouterLink :to="{name: 'user_profile', params: {tab: 'ContactData'}}">Notfallkontakt</RouterLink>
        </p>
      </div>
    </div>

    <br />
  </div>
</template>

<script setup lang="ts">
import {useUserStore} from '@/stores/user';
import {computed} from 'vue';

const userStore = useUserStore();

const properties = computed(() => userStore.personnelData.properties);
const updateRequests = computed(() => userStore.personnelData.update_requests);

const hasRegistrationAddress = computed(() => !!properties.value.registration_address);

const hasBankAccount = computed(() => !!properties.value.bank_account || !!updateRequests.value.bank_account);
const hasPersonalTaxNumber = computed(
  () => !!properties.value.personal_tax_number || !!updateRequests.value.personal_tax_number,
);
const hasSocialSecurityData = computed(() => !!properties.value.payroll_data || !!updateRequests.value.payroll_data);
const hasHealthInsurance = computed(
  () => !!properties.value.health_insurance || !!updateRequests.value.health_insurance,
);

// @todo add personal_tax_number and health_insurance after 2024-10-08
const hasPayrollData = computed(() => hasBankAccount.value && hasSocialSecurityData.value);

const hasEmergencyContact = computed(() => !!properties.value.emergency_contact);

const hasAllValues = computed(() => hasRegistrationAddress.value && hasPayrollData.value && hasEmergencyContact.value);

const hasWarnings = computed(() => userStore.personnelData.employee_id && !hasAllValues.value);
</script>
