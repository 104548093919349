/**
 * @generated
 **/
import {defineStore} from 'pinia';
import {shallowRef} from 'vue';
import {command, fetchAndMergeResult, generateAggregateId, generateGetterById, query} from '@/stores/api';

export const useEmployeeQualificationStore = defineStore('employee_qualification', () => {
  const qualifications = shallowRef<Qualifications>({});
  const employeeQualifications = shallowRef<EmployeeQualifications>({});

  const getQualificationById = generateGetterById<Qualification>(qualifications);
  const getEmployeeQualificationById = generateGetterById<EmployeeQualification>(employeeQualifications);

  const fetchQualifications = async (filter: {[key: string]: any} = {}): Promise<void> => {
    return fetchAndMergeResult('employee-qualification.find-qualifications', qualifications, filter);
  };

  const fetchQualification = async (filter: {qualification_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('employee-qualification.find-qualification', qualifications, filter);
  };

  const fetchQualificationsFromEmployee = async (
    filter: {employee_id: string} & {[key: string]: any},
  ): Promise<void> => {
    return fetchAndMergeResult(
      'employee-qualification.find-qualifications-from-employee',
      employeeQualifications,
      filter,
    );
  };

  const createQualification = async (data: {
    qualification_id?: string;
    qualification_name: string;
    qualification_required_before_effort: boolean;
    expiry_months: any;
  }): Promise<void> => {
    data['qualification_id'] ??= generateAggregateId();
    await command('employee-qualification.create-qualification', data);
  };

  const confirmQualification = async (data: {
    employee_qualification_id?: string;
    qualification_id: string;
    qualification_acquisition_date: any;
  }): Promise<void> => {
    data['employee_qualification_id'] ??= generateAggregateId();
    await command('employee-qualification.confirm-qualification', data);
  };

  const setQualificationFile = async (data: {
    employee_qualification_id?: string;
    qualification_id: string;
    qualification_file_key: string;
  }): Promise<void> => {
    data['employee_qualification_id'] ??= generateAggregateId();
    await command('employee-qualification.set-qualification-file', data);
  };

  return {
    qualifications,
    employeeQualifications,
    getQualificationById,
    getEmployeeQualificationById,
    fetchQualifications,
    fetchQualification,
    fetchQualificationsFromEmployee,
    createQualification,
    confirmQualification,
    setQualificationFile,
  };
});

export interface Qualification {
  qualification_id: string;
  qualification_name: string;
  required_before_effort: boolean;
  expires_after_months: number | null;
}

export interface EmployeeQualification {
  employee_id: string;
  qualifications: {[key: string]: {acquired_at: string; file?: string}};
}

export interface Qualifications {
  [key: string]: Qualification;
}

export interface EmployeeQualifications {
  [key: string]: EmployeeQualification;
}
