<template>
  <RouterLink :to="{name: 'food_vendor_profile'}" class="item" activeClass="active">
    <i class="address card icon" />
    Firma
  </RouterLink>
  <RouterLink :to="{name: 'food_vendor_kiosks'}" class="item" activeClass="active">
    <i class="store icon" />
    Verkaufsmodule
  </RouterLink>
  <RouterLink :to="{name: 'food_vendor_assortments'}" class="item" activeClass="active">
    <i class="utensils icon" />
    Sortimente
  </RouterLink>
  <RouterLink :to="{name: 'food_vendor_events'}" class="item" activeClass="active">
    <i class="calendar icon" />
    Veranstaltungen
  </RouterLink>
  <RouterLink :to="{name: 'food_vendor_invoices'}" class="item" activeClass="active">
    <i class="file invoice icon" />
    Rechnungen
  </RouterLink>
</template>

<script setup lang="ts"></script>
