/**
 * @generated
 **/
import {defineStore} from 'pinia';
import {shallowRef} from 'vue';
import {command, fetchAndMergeResult, generateAggregateId, generateGetterById, query} from '@/stores/api';

export const useEmploymentContractStore = defineStore('employment_contract', () => {
  const employmentContracts = shallowRef<EmploymentContracts>({});
  const employmentDocuments = shallowRef<EmploymentDocuments>({});

  const getEmploymentContractById = generateGetterById<EmploymentContract>(employmentContracts);
  const getEmploymentDocumentById = generateGetterById<EmploymentDocument>(employmentDocuments);

  const fetchEmploymentContract = async (
    filter: {employment_contract_id: string} & {[key: string]: any},
  ): Promise<void> => {
    return fetchAndMergeResult('employment-contract.find-employment-contract', employmentContracts, filter);
  };

  const fetchEmploymentContractsFromEmployee = async (
    filter: {employee_id: string} & {[key: string]: any},
  ): Promise<void> => {
    return fetchAndMergeResult(
      'employment-contract.find-employment-contracts-from-employee',
      employmentContracts,
      filter,
    );
  };

  const createEmploymentContract = async (data: {
    employment_contract_id?: string;
    employee_id: string;
    location_id: string;
    contract_term: object;
  }): Promise<void> => {
    data['employment_contract_id'] ??= generateAggregateId();
    await command('employment-contract.create-employment-contract', data);
  };

  const confirmEmploymentContract = async (data: {employment_contract_id?: string}): Promise<void> => {
    data['employment_contract_id'] ??= generateAggregateId();
    await command('employment-contract.confirm-employment-contract', data);
  };

  const confirmEmploymentDocument = async (data: {
    employment_contract_id?: string;
    document_token: string;
  }): Promise<void> => {
    data['employment_contract_id'] ??= generateAggregateId();
    await command('employment-contract.confirm-employment-document', data);
  };

  return {
    employmentContracts,
    employmentDocuments,
    getEmploymentContractById,
    getEmploymentDocumentById,
    fetchEmploymentContract,
    fetchEmploymentContractsFromEmployee,
    createEmploymentContract,
    confirmEmploymentContract,
    confirmEmploymentDocument,
  };
});

export interface EmploymentContract {
  employment_contract_id: string;
  employee_id: string;
  begins_at: string;
  ends_at: string;
  confirmed: boolean;
  documents: object;
}

export interface EmploymentDocument {
  document_token: string;
  name: string;
}

export interface EmploymentContracts {
  [key: string]: EmploymentContract;
}

export interface EmploymentDocuments {
  [key: string]: EmploymentDocument;
}
