<template>
  <div class="ui dropdown item" :class="{active}" ref="dropdown" @click="onClick">
    <slot name="text">
      <i class="bars icon" />
    </slot>
    <i v-if="$slots.text" class="dropdown icon" />
    <div class="menu" ref="menu" :style="{transformOrigin: 'top center', display: visible ? 'block' : 'none'}">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import {onClickOutside} from '@vueuse/core';
import {ref, watch} from 'vue';

const dropdown = ref<HTMLDivElement>();
const menu = ref<HTMLDivElement>();

const active = ref(false);
const visible = ref(false);

watch(active, (value: boolean) => {
  value ? onShow() : onHide();
});

const onShow = () => {
  menu.value?.animate([{transform: 'scaleY(0)'}, {transform: 'scaleY(1)'}], 150);
  visible.value = true;
};

const onHide = () => {
  const animation = menu.value?.animate([{transform: 'scaleY(1)'}, {transform: 'scaleY(0)'}], 150);

  if (animation) {
    animation.onfinish = () => (visible.value = false);
  }
};

const onClick = () => (active.value = !active.value);

onClickOutside(dropdown, () => (active.value = false));
</script>
