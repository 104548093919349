import {unref} from 'vue';
import type {MaybeRef} from 'vue';

export const sortObject = <T extends object>(
  object: MaybeRef<T>,
  sortFunction: (leftItem: T[keyof T], rightItem: T[keyof T]) => number,
): T => {
  // @ts-ignore
  return Object.fromEntries(Object.entries(unref(object)).sort(([, left], [, right]) => sortFunction(left, right)));
};

export const filterObject = <T extends object>(
  object: MaybeRef<T>,
  filterFunction: (item: T[keyof T]) => boolean,
): T => {
  return Object.fromEntries(Object.entries(unref(object)).filter(([, item]) => filterFunction(item))) as T;
};

export const findObject = <T extends object>(
  object: MaybeRef<T>,
  findFunction: (item: T[keyof T]) => boolean,
): T[keyof T] | undefined => {
  return Object.values(unref(object)).find((item: T[keyof T]) => findFunction(item));
};

export const groupObject = <T extends {[key: string]: {[key: string]: any}}, K extends string>(
  object: MaybeRef<T>,
  field: string,
): Record<K, T> => {
  return Object.entries(unref(object)).reduce(
    (group, [key, item]) => {
      (group[item[field]] = group[item[field]] || {})[key] = item;
      return group;
    },
    {} as Record<K, T>,
  );
};

export const objectLength = <T extends object>(object: MaybeRef<T>): number => {
  return Object.keys(unref(object)).length;
};
