<template>
  <AppTitle title="Gastrobuero Jobs"></AppTitle>
  <div id="app">
    <header class="app-header">
      <NavigationMenu />
    </header>
    <div class="app-content">
      <Warnings />
      <RouterView />
    </div>
    <footer class="app-footer"></footer>
  </div>
</template>

<style>
#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.app-header {
  margin-bottom: 20px;
}

.app-content {
  flex: 1;
}

.app-footer {
  padding-bottom: 1em;
}

@media only screen and (max-width: 767px) {
  [class*='mobile hidden'],
  [class*='tablet only']:not(.mobile),
  [class*='computer only']:not(.mobile),
  [class*='large monitor only']:not(.mobile),
  [class*='widescreen monitor only']:not(.mobile),
  [class*='or lower hidden'] {
    display: none !important;
  }
}
</style>

<script setup lang="ts">
import '@/main.less';
import AppTitle from '@/AppTitle.vue';
import NavigationMenu from '@/components/Navigation/NavigationMenu.vue';
import Warnings from '@/components/User/Warnings.vue';
import {JOB_COMMITMENT_STATES, JOB_PARTICIPATION_STATES} from '@/injection_keys';
import {useUserStore} from '@/stores/user';
import {computed, onBeforeMount, onMounted, provide, watch} from 'vue';
import {useRouter} from 'vue-router';
import {useAuthStore} from '@/stores/auth';

const authStore = useAuthStore();
const userStore = useUserStore();
const router = useRouter();

const roles = computed(() => authStore.userProfile.roles);

const commitmentStates = {
  requested: {
    icon: 'teal question circle outline',
    label: 'angefragt',
  },
  accepted: {
    icon: 'yellow check circle outline',
    label: 'verfügbar',
  },
  declined: {
    icon: 'grey circle outline',
    label: 'nicht verfügbar',
  },
  dismissed: {
    icon: 'red calendar minus outline',
    label: 'nicht dabei',
  },
  appointed: {
    icon: 'blue calendar check outline',
    label: 'eingeteilt',
  },
  confirmed: {
    icon: 'green calendar check',
    label: 'bestätigt',
  },
};

const participationStates = {
  requested: {
    icon: 'teal question circle outline',
    label: 'angefragt',
  },
  accepted: {
    icon: 'yellow check circle outline',
    label: 'verfügbar',
  },
  declined: {
    icon: 'grey circle outline',
    label: 'nicht verfügbar',
  },
  dismissed: {
    icon: 'red calendar minus outline',
    label: 'nicht dabei',
  },
  appointed: {
    icon: 'blue calendar check outline',
    label: 'eingeteilt',
  },
};

provide(JOB_COMMITMENT_STATES, commitmentStates);
provide(JOB_PARTICIPATION_STATES, participationStates);

watch([roles, router.currentRoute], () => {
  if (router.currentRoute.value.name !== 'login') {
    return;
  }

  if (authStore.isJobAdmin) {
    router.push({name: 'events'});
    return;
  }

  if (authStore.isWorkerManager) {
    router.push({name: 'worker_manager_events'});
    return;
  }

  if (authStore.isAdmission) {
    router.push({name: 'admissions'});
    return;
  }

  if (authStore.isEmployee) {
    router.push({name: 'user_commitments'});
    return;
  }
});

onBeforeMount(async () => {
  await Promise.all([authStore.fetchUserProfile(), userStore.fetchMyPersonnelData()]);
});

onMounted(() => {
  window.addEventListener('vite:preloadError', (event) => {
    event.preventDefault();
    window.location.reload();
  });
});
</script>
