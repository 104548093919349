<template>
  <nav class="ui top attached inverted menu">
    <div class="ui container" v-if="isJobAdmin">
      <DropdownItem v-if="isMobile">
        <AdminMenu />
      </DropdownItem>
      <AdminMenu v-else />
      <div class="right menu">
        <DropdownItem v-if="isEmployee">
          <template #text>
            <i class="user icon" />
            {{ fullName ?? 'Administrator' }}
            <span class="ui red empty circular label" v-if="hasEmployeeUpdates" />
          </template>
          <EmployeeMenu ref="employeeMenu" />
        </DropdownItem>
        <RouterLink to="/logout" class="item" activeClass="active">
          <i class="sign out alternate icon"></i>
          Abmelden
        </RouterLink>
      </div>
    </div>
    <div class="ui container" v-else-if="isWorkerManager">
      <WorkerManagerMenu />
      <div class="right menu">
        <RouterLink to="/logout" class="item" activeClass="active">
          <i class="sign out alternate icon"></i>
          Abmelden
        </RouterLink>
      </div>
    </div>
    <div class="ui container" v-else-if="isAdmission">
      <AdmissionMenu />
      <div class="right menu">
        <RouterLink to="/logout" class="item" activeClass="active">
          <i class="sign out alternate icon"></i>
          Abmelden
        </RouterLink>
      </div>
    </div>
    <div class="ui container" v-else-if="isEmployee">
      <DropdownItem v-if="isMobile">
        <template #text>
          <span class="ui red empty circular label" v-if="hasEmployeeUpdates" />
        </template>
        <EmployeeMenu ref="employeeMenu" />
      </DropdownItem>
      <EmployeeMenu v-else />
      <div class="right menu">
        <RouterLink to="/logout" class="item" activeClass="active">
          <i class="sign out alternate icon"></i>
          Abmelden
        </RouterLink>
      </div>
    </div>
    <div class="ui text container" v-else>
      <GuestMenu />
    </div>
  </nav>
</template>

<script setup lang="ts">
import AdminMenu from '@/components/Navigation/AdminMenu.vue';
import AdmissionMenu from '@/components/Navigation/AdmissionMenu.vue';
import DropdownItem from '@/components/Navigation/DropdownItem.vue';
import EmployeeMenu from '@/components/Navigation/EmployeeMenu.vue';
import GuestMenu from '@/components/Navigation/GuestMenu.vue';
import WorkerManagerMenu from '@/components/Navigation/WorkerManagerMenu.vue';
import {useIsMobile} from '@/composables/browser';
import {useAuthStore} from '@/stores/auth';
import {useUserStore} from '@/stores/user';
import {computed, ref} from 'vue';

const isMobile = useIsMobile();

const authStore = useAuthStore();
const userStore = useUserStore();

const isEmployee = computed(() => authStore.isEmployee);
const isAdmission = computed(() => authStore.isAdmission);
const isWorkerManager = computed(() => authStore.isWorkerManager);
const isJobAdmin = computed(() => authStore.isJobAdmin);

const fullName = computed(() => userStore.personnelData?.full_name);

const employeeMenu = ref();
const hasEmployeeUpdates = computed(
  () =>
    employeeMenu.value?.hasNewRequests || employeeMenu.value?.hasNewCommitments || employeeMenu.value?.hasNewTrainings,
);
</script>
