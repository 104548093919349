/**
 * @generated
 **/
import {defineStore} from 'pinia';
import {shallowRef} from 'vue';
import {command, fetchAndMergeResult, generateAggregateId, generateGetterById, query} from '@/stores/api';

export const useAccountingStore = defineStore('accounting', () => {
  const costCenters = shallowRef<CostCenters>({});
  const costObjects = shallowRef<CostObjects>({});
  const settlements = shallowRef<Settlements>({});
  const mySettlements = shallowRef<MySettlements>({});
  const wageBonuses = shallowRef<WageBonuses>({});
  const myWageBonuses = shallowRef<MyWageBonuses>({});
  const wageProfiles = shallowRef<WageProfiles>({});
  const myWageProfiles = shallowRef<MyWageProfiles>({});
  const taxProfiles = shallowRef<TaxProfiles>({});

  const getCostCenterById = generateGetterById<CostCenter>(costCenters);
  const getCostObjectById = generateGetterById<CostObject>(costObjects);
  const getSettlementById = generateGetterById<Settlement>(settlements);
  const getMySettlementById = generateGetterById<MySettlement>(mySettlements);
  const getWageBonusById = generateGetterById<WageBonus>(wageBonuses);
  const getMyWageBonusById = generateGetterById<MyWageBonus>(myWageBonuses);
  const getWageProfileById = generateGetterById<WageProfile>(wageProfiles);
  const getMyWageProfileById = generateGetterById<MyWageProfile>(myWageProfiles);
  const getTaxProfileById = generateGetterById<TaxProfile>(taxProfiles);

  const fetchCostCenters = async (filter: {[key: string]: any} = {}): Promise<void> => {
    return fetchAndMergeResult('accounting.find-cost-centers', costCenters, filter);
  };

  const fetchCostObjects = async (filter: {[key: string]: any} = {}): Promise<void> => {
    return fetchAndMergeResult('accounting.find-cost-objects', costObjects, filter);
  };

  const fetchSettlement = async (filter: {settlement_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('accounting.find-settlement', settlements, filter);
  };

  const fetchSettlements = async (filter: {settlement_id: string[]} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('accounting.find-settlements', settlements, filter);
  };

  const fetchSettlementsForMonth = async (filter: {month: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('accounting.find-settlements-for-month', settlements, filter);
  };

  const fetchSettlementsForEmployee = async (filter: {employee_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('accounting.find-settlements-for-employee', settlements, filter);
  };

  const fetchSettlementsForEvent = async (filter: {event_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('accounting.find-settlements-for-event', settlements, filter);
  };

  const fetchMySettlements = async (filter: {employee_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('accounting.find-my-settlements', mySettlements, filter);
  };

  const fetchWageProfiles = async (filter: {[key: string]: any} = {}): Promise<void> => {
    return fetchAndMergeResult('accounting.find-wage-profiles', wageProfiles, filter);
  };

  const fetchWageBonuses = async (filter: {[key: string]: any} = {}): Promise<void> => {
    return fetchAndMergeResult('accounting.find-wage-bonuses', wageBonuses, filter);
  };

  const fetchMyWageProfile = async (filter: {wage_profile_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('accounting.find-my-wage-profile', myWageProfiles, filter);
  };

  const fetchMyWageBonus = async (filter: {wage_bonus_id: string} & {[key: string]: any}): Promise<void> => {
    return fetchAndMergeResult('accounting.find-my-wage-bonus', myWageBonuses, filter);
  };

  const fetchTaxProfiles = async (filter: {[key: string]: any} = {}): Promise<void> => {
    return fetchAndMergeResult('accounting.find-tax-profiles', taxProfiles, filter);
  };

  const addCostObject = async (data: {
    cost_object_id?: string;
    cost_object_number: string;
    cost_object_label: string;
  }): Promise<void> => {
    data['cost_object_id'] ??= generateAggregateId();
    await command('accounting.add-cost-object', data);
  };

  const addCostCenter = async (data: {
    cost_center_id?: string;
    cost_center_number: string;
    cost_center_label: string;
  }): Promise<void> => {
    data['cost_center_id'] ??= generateAggregateId();
    await command('accounting.add-cost-center', data);
  };

  const addWageProfile = async (data: {
    wage_profile_id?: string;
    wage_profile_label: string;
    wage_profile_rate: number;
    wage_profile_imputed_costs: number;
  }): Promise<void> => {
    data['wage_profile_id'] ??= generateAggregateId();
    await command('accounting.add-wage-profile', data);
  };

  const disableWageProfile = async (data: {wage_profile_id?: string}): Promise<void> => {
    data['wage_profile_id'] ??= generateAggregateId();
    await command('accounting.disable-wage-profile', data);
  };

  const addWageBonus = async (data: {
    wage_bonus_id?: string;
    wage_bonus_months: number;
    wage_bonus_rate: number;
  }): Promise<void> => {
    data['wage_bonus_id'] ??= generateAggregateId();
    await command('accounting.add-wage-bonus', data);
  };

  const addTaxProfile = async (data: {
    tax_profile_id?: string;
    tax_profile_label: string;
    tax_profile_rate: number;
  }): Promise<void> => {
    data['tax_profile_id'] ??= generateAggregateId();
    await command('accounting.add-tax-profile', data);
  };

  const disableTaxProfile = async (data: {tax_profile_id?: string}): Promise<void> => {
    data['tax_profile_id'] ??= generateAggregateId();
    await command('accounting.disable-tax-profile', data);
  };

  const createSettlement = async (data: {
    settlement_id?: string;
    commitment_id: string;
    employee_id: string;
    task_id: string;
    event_id: string;
    effort_day: any;
  }): Promise<void> => {
    data['settlement_id'] ??= generateAggregateId();
    await command('accounting.create-settlement', data);
  };

  const recordSettlementToAccounts = async (data: {settlement_id?: string; settlement_sum: number}): Promise<void> => {
    data['settlement_id'] ??= generateAggregateId();
    await command('accounting.record-settlement-to-accounts', data);
  };

  const withdrawSettlement = async (data: {settlement_id?: string}): Promise<void> => {
    data['settlement_id'] ??= generateAggregateId();
    await command('accounting.withdraw-settlement', data);
  };

  return {
    costCenters,
    costObjects,
    settlements,
    mySettlements,
    wageBonuses,
    myWageBonuses,
    wageProfiles,
    myWageProfiles,
    taxProfiles,
    getCostCenterById,
    getCostObjectById,
    getSettlementById,
    getMySettlementById,
    getWageBonusById,
    getMyWageBonusById,
    getWageProfileById,
    getMyWageProfileById,
    getTaxProfileById,
    fetchCostCenters,
    fetchCostObjects,
    fetchSettlement,
    fetchSettlements,
    fetchSettlementsForMonth,
    fetchSettlementsForEmployee,
    fetchSettlementsForEvent,
    fetchMySettlements,
    fetchWageProfiles,
    fetchWageBonuses,
    fetchMyWageProfile,
    fetchMyWageBonus,
    fetchTaxProfiles,
    addCostObject,
    addCostCenter,
    addWageProfile,
    disableWageProfile,
    addWageBonus,
    addTaxProfile,
    disableTaxProfile,
    createSettlement,
    recordSettlementToAccounts,
    withdrawSettlement,
  };
});

export interface CostCenter {
  cost_center_id: string;
  cost_center_number: string;
  cost_center_label: string;
}

export interface CostObject {
  cost_object_id: string;
  cost_object_number: string;
  cost_object_label: string;
}

export interface Settlement {
  settlement_id: string;
  commitment_id: string;
  employee_id: string;
  event_id: string;
  task_id: string;
  month: string;
  effort_day: string;
  billing: {wage_profile_id: string; wage_bonus_id: string; paused_minutes: number; worked_minutes: number} | null;
  accounting_profiles: {cost_object_id: string; cost_center_id: string} | null;
  expenditures: {travel_expenses?: number};
  recorded: boolean;
  recorded_settlement_sum: number | null;
}

export interface MySettlement {
  settlement_id: string;
  commitment_id: string;
  employee_id: string;
  event_id: string;
  task_id: string;
  month: string;
  effort_day: string;
  billing: {wage_profile_id: string; wage_bonus_id: string; paused_minutes: number; worked_minutes: number} | null;
  expenditures: {travel_expenses?: number};
}

export interface WageBonus {
  wage_bonus_id: string;
  wage_bonus_months: number;
  wage_bonus_rate: number;
}

export interface MyWageBonus {
  wage_bonus_id: string;
  wage_bonus_months: number;
  wage_bonus_rate: number;
}

export interface WageProfile {
  wage_profile_id: string;
  wage_profile_label: string;
  wage_profile_rate: number;
  wage_profile_imputed_costs: number;
  active: boolean;
}

export interface MyWageProfile {
  wage_profile_id: string;
  wage_profile_rate: number;
}

export interface TaxProfile {
  tax_profile_id: string;
  tax_profile_label: string;
  tax_profile_rate: number;
  active: boolean;
}

export interface CostCenters {
  [key: string]: CostCenter;
}

export interface CostObjects {
  [key: string]: CostObject;
}

export interface Settlements {
  [key: string]: Settlement;
}

export interface MySettlements {
  [key: string]: MySettlement;
}

export interface WageBonuses {
  [key: string]: WageBonus;
}

export interface MyWageBonuses {
  [key: string]: MyWageBonus;
}

export interface WageProfiles {
  [key: string]: WageProfile;
}

export interface MyWageProfiles {
  [key: string]: MyWageProfile;
}

export interface TaxProfiles {
  [key: string]: TaxProfile;
}
