<template>
  <RouterLink to="/events" class="item" activeClass="active">
    <i class="yellow announcement icon"></i>
    Veranstaltungen
  </RouterLink>
  <RouterLink to="/courses" class="item" activeClass="active">
    <i class="olive chalkboard teacher icon"></i>
    Lehrgänge
  </RouterLink>
  <RouterLink to="/wages" class="item" activeClass="active">
    <i class="blue money check icon"></i>
    Löhne
  </RouterLink>
  <RouterLink to="/employees" class="item" activeClass="active">
    <i class="address book outline icon"></i>
    Mitarbeiter:innen
  </RouterLink>
  <RouterLink to="/applications" class="item" activeClass="active">
    <i class="user clock icon"></i>
    Bewerbungen
  </RouterLink>
  <template v-if="isSystem">
    <div class="item" @click="toggleDebug">
      <i class="ui eye icon" :class="{violet: showDebug}"></i>
    </div>
  </template>
</template>

<script setup lang="ts">
import {useAuthStore} from '@/stores/auth';
import {computed} from 'vue';

const authStore = useAuthStore();

const isSystem = computed(() => authStore.isSystem);
const showDebug = computed(() => authStore.showDebug);
const toggleDebug = async () => authStore.toggleDebug();
</script>
