import {useLoaderStore} from '@/stores/loader';
import axios, {type AxiosResponse} from 'axios';
import uuid4 from 'uuid4';
import {computed} from 'vue';
import type {ComputedRef, Ref} from 'vue';
import * as Sentry from '@sentry/browser';

const http = axios.create();

http.interceptors.request.use(
  async (config) => {
    useLoaderStore().processes++;
    return config;
  },
  async (error) => {
    useLoaderStore().processes--;
    Sentry.captureException(error);

    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  async (response) => {
    useLoaderStore().processes--;
    return response;
  },
  async (error) => {
    useLoaderStore().processes--;
    Sentry.captureException(error);

    return Promise.reject(error);
  },
);

const pollingQueue: AxiosResponse[] = [];
let isPolling = false;

http.interceptors.response.use(async (response: AxiosResponse): Promise<AxiosResponse> => {
  if (response.status !== 202 || !response.headers['location']) {
    return response;
  }

  return new Promise(async (resolve): Promise<void> => {
    pollingQueue.push(response);

    if (!isPolling) {
      isPolling = true;

      while (pollingQueue.length > 0) {
        const entry = pollingQueue.shift();

        if (!entry) {
          break;
        }

        const location = entry.headers['location'];

        let retries = 1;
        let pollingResponse = await http.get(location);

        while (pollingResponse.data.status === 'running') {
          if (retries++ >= 10) {
            break;
          }

          await new Promise<void>((resolve) => {
            setTimeout(resolve, 70 * 1.2 ** (retries - 2));
          });

          pollingResponse = await http.get(location);
        }
      }

      isPolling = false;
    }

    resolve(response);
  });
});

export const generateAggregateId = (): string => {
  return uuid4();
};

export function generateGetterById<Type>(
  data: Ref<Record<string, Type>>,
): ComputedRef<(id?: string) => Type | undefined> {
  return computed(() => (id?: string) => (id !== undefined ? data.value[id] : undefined));
}

export async function fetchAndMergeResult(queryName: string, data: Ref, filter = {}): Promise<void> {
  try {
    const result = await query(queryName, filter);
    data.value = {...data.value, ...result.data};
  } catch (error) {}
}

export async function fetchAndSetResult(queryName: string, data: Ref, filter = {}): Promise<void> {
  try {
    const result = await query(queryName, filter);
    data.value = result.data;
  } catch (error) {}
}

export async function query<T = object>(query: string, filter: object = {}): Promise<AxiosResponse<T>> {
  return http.post('/api/query/' + query, filter, {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  });
}

export async function command(command: string, data: object = {}): Promise<AxiosResponse> {
  return http.post('/api/command/' + command, data, {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export async function message(messageType: string, payload: object = {}): Promise<AxiosResponse> {
  return http.post('/api/message/' + messageType, payload, {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export async function resolve(type: string, payload: object = {}): Promise<AxiosResponse<string>> {
  return http.post('/api/resolve/' + type, payload, {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'text/plain',
    },
  });
}

export async function logout(): Promise<any> {
  return http.post(
    '/auth/logout',
    {},
    {
      withCredentials: true,
    },
  );
}

export async function login(data: object): Promise<AxiosResponse> {
  return http
    .post('/auth/login', data, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .catch((error) => console.error(error));
}

export async function profile(): Promise<AxiosResponse<object>> {
  return http.get('/api/profile', {
    withCredentials: true,
    headers: {
      Accept: 'application/json',
    },
  });
}

export async function employeeSearch(searchValue: string): Promise<AxiosResponse<object>> {
  return http.get('/api/employees/' + searchValue, {
    withCredentials: true,
    headers: {
      Accept: 'application/json',
    },
  });
}

export async function sendEmails(values: object = {}, files: File[] = []): Promise<AxiosResponse> {
  return uploadEmailAttachments(files).then((attachments) =>
    http.post(
      '/api/send_emails',
      {...values, attachments},
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ),
  );
}

export async function uploadFile(type: string, file: File, data: object = {}): Promise<AxiosResponse> {
  const formData = new FormData();

  formData.append('file', file);

  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return http.post('/upload/' + type, formData, {
    withCredentials: true,
  });
}

export async function uploadEmailAttachments(files: File[]): Promise<object[]> {
  if (files.length === 0) {
    return [];
  }

  const formData = new FormData();
  Array.from(files).forEach((file, index) => {
    formData.append(`file-${index}`, file);
  });

  return http
    .post('/upload/email_attachment', formData, {
      withCredentials: true,
    })
    .then((response) => response.data.files);
}

export async function downloadFile(type: string, filter: object = {}): Promise<any> {
  return http
    .post('/download/' + type, filter, {
      withCredentials: true,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'unknown';
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename=(.+)/);
        if (fileNameMatch && fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }

      const link = document.createElement('a');
      link.href = URL.createObjectURL(response.data);
      link.setAttribute('download', fileName);
      link.click();

      URL.revokeObjectURL(link.href);
    });
}
