<template>
  <RouterLink to="/requests" class="item" activeClass="active">
    <i class="list icon"></i>
    Anfragen
    <span class="ui red empty circular label" v-if="hasNewRequests" />
  </RouterLink>
  <RouterLink :to="{name: 'user_commitments'}" class="item" activeClass="active">
    <i class="calendar icon"></i>
    Arbeitseinsätze
    <span class="ui red empty circular label" v-if="hasNewCommitments" />
  </RouterLink>
  <RouterLink :to="{name: 'user_trainings'}" class="item" activeClass="active">
    <i class="graduation cap icon"></i>
    Schulungen
    <span class="ui red empty circular label" v-if="hasNewTrainings" />
  </RouterLink>
  <RouterLink :to="{name: 'user_payrolls'}" class="item" activeClass="active">
    <i class="handshake icon"></i>
    Lohnabrechnungen
  </RouterLink>
  <RouterLink :to="{name: 'user_profile'}" class="item" activeClass="active">
    <i class="user icon"></i>
    Profil
  </RouterLink>
</template>

<script setup lang="ts">
import {useUserStore} from '@/stores/user';
import {computed} from 'vue';

const userStore = useUserStore();

const hasNewRequests = computed(() => Object.keys(userStore.requestsByEvent).length !== 0);

const hasNewCommitments = computed(() =>
  Object.values(userStore.commitments).some((commitment) => commitment.status === 'appointed' && !commitment.confirmed),
);

const hasNewTrainings = computed(() => Object.keys(userStore.courseRequests).length !== 0);

defineExpose({
  hasNewRequests,
  hasNewCommitments,
  hasNewTrainings,
});
</script>
