import {defineStore} from 'pinia';
import {computed, reactive, ref} from 'vue';
import {command, generateAggregateId, login, logout, profile} from '@/stores/api';

export const useAuthStore = defineStore('auth', () => {
  const showDebug = ref<boolean>(false);
  const process = ref<string>('init');
  const userProfile = reactive<UserProfile>({
    authenticated: false,
    roles: ['guest'],
  });

  const isEmployee = computed(() => userProfile.roles?.includes('employee'));
  const isAdmission = computed(() => userProfile.roles?.includes('admission'));
  const isWorkerManager = computed(() => userProfile.roles?.includes('worker-manager'));
  const isJobAdmin = computed(() => userProfile.roles?.includes('admin'));
  const isFoodVendor = computed(() => userProfile.roles?.includes('food-vendor'));
  const isFoodAdmin = computed(() => userProfile.roles?.includes('food-admin'));
  const isSystem = computed(() => userProfile.roles?.includes('system'));

  const toggleDebug = () => (showDebug.value = !showDebug.value);

  const fetchUserProfile = async () => {
    const result = await profile();
    Object.assign(userProfile, result.data);
  };

  const loginUser = async (data: Login) => {
    const success = await login(data);

    if (success) {
      process.value = 'succeeded';
      await fetchUserProfile();
      return;
    }

    process.value = 'failed';
  };

  const logoutUser = async () => {
    await logout();
    userProfile.authenticated = false;
    userProfile.roles = ['guest'];
  };

  const submitApplication = async (data: SubmitApplicationCommandData) => {
    data['application_id'] ??= generateAggregateId();
    await command('employee.submit-application', data);
  };

  const activateUser = async (data: ActivateUserCommandData) => {
    await command('new-user-email.set-password', data);
  };

  return {
    showDebug,
    process,
    userProfile,
    isEmployee,
    isAdmission,
    isWorkerManager,
    isJobAdmin,
    isFoodVendor,
    isFoodAdmin,
    isSystem,
    toggleDebug,
    fetchUserProfile,
    loginUser,
    logoutUser,
    submitApplication,
    activateUser,
  };
});

interface UserProfile {
  authenticated: boolean;
  email_identity_id?: string;
  employee_id?: string | null;
  food_vendor_id?: string | null;
  roles: string[];
}

interface Login {
  email_address: string;
  password: string;
}

interface SubmitApplicationCommandData {
  application_id?: string;
  employee_name: object;
  birthday: any;
  email_address: any;
  mobile_number: any;
  place_of_residence: object;
}

interface ActivateUserCommandData {
  new_user_email_id: string;
  password: string;
}
