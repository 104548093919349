import {defineStore} from 'pinia';
import {ref} from 'vue';

export const useLoaderStore = defineStore('loader', () => {
  const processes = ref(0);

  return {
    processes,
  };
});
