<template>
  <RouterLink :to="{name: 'login'}" class="item" activeClass="active"> Login </RouterLink>
  <RouterLink :to="{name: 'application'}" class="item" activeClass="active">
    <i class="pen icon"></i>
    Bewerbung
  </RouterLink>
  <div class="right menu">
    <RouterLink :to="{name: 'imprint'}" class="item" activeClass="active"> Impressum </RouterLink>
  </div>
</template>

<script setup lang="ts"></script>
