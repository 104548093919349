<template>
  <RouterLink to="/food_projects" class="item" activeClass="active">
    <i class="ui yellow announcement icon"></i>
    Veranstaltungen
  </RouterLink>
  <RouterLink to="/food_vendors" class="item" activeClass="active">
    <i class="ui users icon"></i>
    Händler:innen
  </RouterLink>
  <RouterLink to="/food_invoices" class="item" activeClass="active">
    <i class="ui file invoice icon"></i>
    Rechnungen
  </RouterLink>
  <template v-if="isSystem">
    <div class="item" @click="toggleDebug">
      <i class="ui eye icon" :class="{violet: showDebug}"></i>
    </div>
  </template>
</template>

<script setup lang="ts">
import {useAuthStore} from '@/stores/auth';
import {computed} from 'vue';

const authStore = useAuthStore();

const isSystem = computed(() => authStore.isSystem);
const showDebug = computed(() => authStore.showDebug);
const toggleDebug = async () => authStore.toggleDebug();
</script>
