import cloneDeep from 'lodash.clonedeep';
import type {PiniaPluginContext} from 'pinia';

export const StateResetPlugin = ({store}: PiniaPluginContext) => {
  const initialState = cloneDeep(store.$state);
  store.$reset = () => {
    store.$patch(($state) => {
      Object.assign($state, initialState);
    });
  };
};
