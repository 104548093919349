<template>
  <nav class="ui top attached inverted menu">
    <div class="ui container" v-if="isFoodAdmin">
      <DropdownItem v-if="isMobile">
        <FoodAdminMenu />
      </DropdownItem>
      <FoodAdminMenu v-else />
      <div class="right menu">
        <DropdownItem v-if="isFoodVendor">
          <template #text>
            <i class="user icon" />
            Händler:in
          </template>
          <FoodVendorMenu />
        </DropdownItem>
        <RouterLink to="/logout" class="item" activeClass="active">
          <i class="sign out alternate icon"></i>
          Abmelden
        </RouterLink>
      </div>
    </div>
    <div class="ui container" v-else-if="isFoodVendor">
      <DropdownItem v-if="isMobile">
        <FoodVendorMenu />
      </DropdownItem>
      <FoodVendorMenu v-else />
      <div class="right menu">
        <RouterLink to="/logout" class="item" activeClass="active">
          <i class="sign out alternate icon"></i>
          Abmelden
        </RouterLink>
      </div>
    </div>
    <div class="ui text container" v-else>
      <FoodGuestMenu />
    </div>
  </nav>
</template>

<script setup lang="ts">
import DropdownItem from '@/components/Navigation/DropdownItem.vue';
import FoodAdminMenu from '@/components/Navigation/FoodAdminMenu.vue';
import FoodGuestMenu from '@/components/Navigation/FoodGuestMenu.vue';
import FoodVendorMenu from '@/components/Navigation/FoodVendorMenu.vue';
import {useIsMobile} from '@/composables/browser';
import {useAuthStore} from '@/stores/auth';
import {computed} from 'vue';

const isMobile = useIsMobile();

const authStore = useAuthStore();

const isFoodAdmin = computed(() => authStore.isFoodAdmin);
const isFoodVendor = computed(() => authStore.isFoodVendor);
</script>
