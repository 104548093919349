<template>
  <span></span>
</template>

<script setup lang="ts">
import {watchEffect} from 'vue';

const props = defineProps<{
  title: string;
}>();

watchEffect(() => {
  document.title = props.title;
});
</script>
